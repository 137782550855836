.home {
  &__info {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    width: 50%;
    img {
      width: 100%;
      min-width: 500px;
      max-width: 700px;
      @include respond(phone) {
        min-width: unset;
      }
    }
    @include respond(phone) {
      margin-bottom: 60px;
      width: 100%;
    }
    h1 {
      font-family: "Abril Fatface";
      font-size: 135px;
      color: white;
      margin: 0;
      line-height: 0.8;
      text-shadow: black 1px 0 10px;
      text-align: center;
      @include respond(phone) {
        font-size: 80px;
      }
    }
  }

  &__text {
    font-family: "Glacial-Indifference-regular";
    text-transform: uppercase;
    color: white;
    font-size: 20px;
    text-shadow: black 7px 0 20px;
    letter-spacing: 3px;
    margin: 0 auto 30px;
    text-align: center;
    @include respond(phone) {
      font-size: 14px;
      margin: 0 20px 20px;
    }
  }

  &__contacts {
    position: absolute;
    bottom: 20px;
    right: 20px;
    display: flex;
    gap: 20px;

    &--mobile {
      gap: 20px;
      width: 100%;
      justify-content: center;
    }
  }

  &__contact {
    color: white;
    font-size: 30px;
    text-shadow: black 7px 0 20px;
  }

  &__link {
    color: inherit;
    text-decoration: none;
  }
}
