@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media only screen and (max-width: 47.6em) {
      //767px
      @content;
    }
  }
  @if $breakpoint == tablet {
    //1060px
    @media only screen and (max-width: 66.25em) {
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    //1800px
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
}
