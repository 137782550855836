@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap");

@font-face {
  font-family: "Glacial-Indifference-regular";
  src: url("../../assets/fonts/GlacialIndifference-Regular.otf");
  src: url("../../assets/fonts/GlacialIndifference-Regular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Glacial-Indifference-bold";
  src: url("../../assets/fonts/GlacialIndifference-Bold.otf");
  src: url("../../assets/fonts/GlacialIndifference-Bold.otf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "pby";
  src: url("../../assets/icons/pby.eot?af4q48");
  src: url("../../assets/icons/pby.eot?af4q48#iefix")
      format("embedded-opentype"),
    url("../../assets/icons/pby.ttf?af4q48") format("truetype"),
    url("../../assets/icons/pby.woff?af4q48") format("woff"),
    url("../../assets/icons/pby.svg?af4q48#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "pby" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
