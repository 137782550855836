.banner {
  &__container {
    width: 100%;
    height: 100vh;
    position: relative;
  }

  &__img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  &__frame {
    box-sizing: border-box;
    border: 6px solid white;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    height: calc(100vh - 60px);
    margin: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    z-index: 2;
  }

  &__gradient {
    width: 100%;
    height: 20vh;
    background: linear-gradient(to bottom, transparent 0%, black 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  &__brand {
    font-size: 28px;
    color: white;
    margin-top: calc(100vh - 125px);
    margin-right: 30px;
  }
}
